@use "colors" as *;
@use "antd-button" as *;
@use "antd-card" as *;
@use "antd-pagination" as *;
@use "antd-select-dropdown" as *;
@use "antd-switch" as *;

@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@600&family=Gravitas+One&family=Playfair:ital,opsz,wght@0,5..1200,300..900;1,5..1200,300..900&family=Julius+Sans+One&display=swap");

* {
  box-sizing: border-box;
}

/* Fonts:
   font-family: "Playfair", cursive;
   font-family: 'Cormorant Garamond', serif;
   font-family: 'Julius Sans One', sans-serif;
*/

body {
  background-color: $color1;
  color: $color4;
  margin: 0px;
  font-family: "Cormorant Garamond", serif;
}

.ApplicationPage {
  background-color: $color1;
  margin: 0px auto;
  min-width: 600px;
  max-width: 1000px;
  padding: 40px;
  min-height: calc(
    100vh - var(--header-height) - var(--navbar-height) - var(--footer-height)
  );
}

h1 {
  font-family: "Cormorant Garamond", serif;
  color: $color3;
}

h2 {
  font-family: "Julius Sans One", sans-serif;
  color: $color5;
}

h3 {
  font-family: "Julius Sans One", sans-serif;
  color: $color6;
}

h4 {
  font-family: "Playfair", cursive;
  color: $color4;
}

h5 {
  font-family: "Julius Sans One", sans-serif;
  color: $color3;
}

p {
  color: $color2;
}

form {
  font-family: "Cormorant Garamond", serif;
}

a {
  color: $color1;
}

a:hover {
  color: $color6;
}

a:active {
  color: $color6;
}

a:visited {
  color: $color6;
}
