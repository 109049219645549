@import "https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@600&family=Gravitas+One&family=Playfair:ital,opsz,wght@0,5..1200,300..900;1,5..1200,300..900&family=Julius+Sans+One&display=swap";
.ant-btn-primary {
  color: #fbf9e7 !important;
  background-color: #2b2926 !important;
  border-color: #2b2926 !important;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fbf9e7 !important;
  background-color: #615d56 !important;
  border-color: #615d56 !important;
}

.ant-btn-primary:active {
  color: #fbf9e7 !important;
  background-color: #100f0e !important;
  border-color: #100f0e !important;
}

.ant-btn-primary:disabled {
  color: #000 !important;
  background-color: #e3e0c8 !important;
  border-color: #e3e0c8 !important;
}

.ant-btn-default {
  color: #000 !important;
  background-color: #fbf9e7 !important;
  border-color: #000 !important;
}

.ant-btn-default:hover {
  color: #d43229 !important;
  background-color: #e3e0c8 !important;
  border-color: #d43229 !important;
}

.ant-btn-default:active {
  color: #802e28 !important;
  background-color: #e3e0c8 !important;
  border-color: #802e28 !important;
}

.ant-btn-default:disabled {
  color: #000 !important;
  background-color: #e3e0c8 !important;
  border-color: #e3e0c8 !important;
}

.ant-btn-dashed {
  color: #2b2926 !important;
  background-color: #fbf9e7 !important;
  border-style: dashed !important;
  border-color: #2b2926 !important;
}

.ant-btn-dashed:hover {
  color: #100f0e !important;
  background-color: #e3e0c8 !important;
  border-color: #46433e !important;
}

.ant-btn-dashed:active {
  color: #fbf9e7 !important;
  background-color: #2b2926 !important;
  border-color: #000 !important;
}

.ant-btn-dashed:disabled {
  color: #000 !important;
  background-color: #e3e0c8 !important;
  border-color: #e3e0c8 !important;
}

.ant-btn-danger, .ant-btn-dangerous {
  color: #fbf9e7 !important;
  background-color: #d43229 !important;
  border-color: #d43229 !important;
}

.ant-btn-danger:hover, .ant-btn-danger:focus, .ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #fbf9e7 !important;
  background-color: #e26e68 !important;
  border-color: #e26e68 !important;
}

.ant-btn-danger:active, .ant-btn-dangerous:active {
  color: #fbf9e7 !important;
  background-color: #a92821 !important;
  border-color: #a92821 !important;
}

.ant-btn-danger:disabled, .ant-btn-dangerous:disabled {
  color: #000 !important;
  background-color: #802e28 !important;
  border-color: #802e28 !important;
}

.antd-card {
  color: #fbf9e7;
  background-color: #2b2926;
  border: 2px solid #000;
  border-radius: 7px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
}

.antd-card .ant-card-head {
  color: #fbf9e7;
  background-color: #000;
  border-color: #000;
  border-radius: 0;
  font-family: Cormorant Garamond;
  font-size: 22px;
}

.ant-pagination-item {
  background-color: #e3e0c8 !important;
  border-color: #e3e0c8 !important;
}

.ant-pagination-item a {
  color: #2b2926 !important;
}

.ant-pagination-item-active, .ant-pagination-item-active:focus, .ant-pagination-item-active:hover, .ant-pagination-item-active:active {
  background-color: #2b2926 !important;
  border-color: #000 !important;
}

.ant-pagination-item-active a, .ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a, .ant-pagination-item-active:active a {
  color: #fbf9e7 !important;
}

.ant-pagination-item-active:hover, .ant-pagination-item-active:focus:hover, .ant-pagination-item-active:hover:hover, .ant-pagination-item-active:active:hover {
  background-color: #2b2926 !important;
  border-color: #d43229 !important;
}

.ant-pagination-item-active:hover a, .ant-pagination-item-active:focus:hover a, .ant-pagination-item-active:hover:hover a, .ant-pagination-item-active:active:hover a {
  color: #d43229 !important;
}

.ant-pagination-item:hover {
  background-color: #e3e0c8 !important;
  border-color: #d43229 !important;
}

.ant-pagination-item:hover a {
  color: #d43229 !important;
}

.ant-pagination-item:visited {
  background-color: #000 !important;
  border-color: #000 !important;
}

.ant-pagination-item:visited a {
  color: #fbf9e7 !important;
}

.ant-pagination-item:active {
  background-color: #d43229 !important;
  border-color: #d43229 !important;
}

.ant-pagination-item:active a {
  color: #fbf9e7 !important;
}

.ant-pagination-jump-prev .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-link-icon {
  color: #802e28 !important;
}

.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon, .ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  color: #a73c34 !important;
}

.ant-pagination-jump-prev:focus .ant-pagination-item-link-icon, .ant-pagination-jump-next:focus .ant-pagination-item-link-icon {
  color: #802e28 !important;
}

.ant-select-dropdown {
  color: #fbf9e7 !important;
  background-color: #2b2926 !important;
  border-color: #2b2926 !important;
}

.ant-select-dropdown .ant-select-item {
  color: #fbf9e7 !important;
  background-color: #2b2926 !important;
}

.ant-select-dropdown .ant-select-item:hover {
  color: #fbf9e7 !important;
  background-color: #46433e !important;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-selected {
  color: #2b2926 !important;
  background-color: #802e28 !important;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-disabled {
  color: #fbf9e7 !important;
  background-color: #d43229 !important;
}

.ant-select-dropdown .ant-select-item-option-content {
  color: #fbf9e7 !important;
}

.ant-select-dropdown .ant-select-item-option-state {
  color: #d43229 !important;
}

.ant-select-arrow {
  color: #2b2926 !important;
}

.ant-select-arrow:hover {
  color: #46433e !important;
}

.ant-select-arrow:focus {
  color: #2b2926 !important;
}

.ant-select-selector {
  color: #2b2926 !important;
  background-color: #e3e0c8 !important;
  border-color: #e3e0c8 !important;
}

.ant-select-selector:hover, .ant-select-selector:focus {
  border-color: #d43229 !important;
  box-shadow: 0 0 0 2px #d4322933 !important;
}

.ant-switch {
  background-color: #e3e0c8 !important;
  border-color: #e3e0c8 !important;
}

.ant-switch-checked {
  background-color: #2b2926 !important;
  border-color: #2b2926 !important;
}

.ant-switch-disabled {
  opacity: .5;
  background-color: #802e28 !important;
  border-color: #802e28 !important;
}

.ant-switch-loading {
  background-color: #000 !important;
  border-color: #000 !important;
}

* {
  box-sizing: border-box;
}

body {
  color: #802e28;
  background-color: #fbf9e7;
  margin: 0;
  font-family: Cormorant Garamond, serif;
}

.ApplicationPage {
  min-height: calc(100vh - var(--header-height)  - var(--navbar-height)  - var(--footer-height));
  background-color: #fbf9e7;
  min-width: 600px;
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px;
}

h1 {
  color: #000;
  font-family: Cormorant Garamond, serif;
}

h2 {
  color: #d43229;
  font-family: Julius Sans One, sans-serif;
}

h3 {
  color: #e3e0c8;
  font-family: Julius Sans One, sans-serif;
}

h4 {
  color: #802e28;
  font-family: Playfair, cursive;
}

h5 {
  color: #000;
  font-family: Julius Sans One, sans-serif;
}

p {
  color: #2b2926;
}

form {
  font-family: Cormorant Garamond, serif;
}

a {
  color: #fbf9e7;
}

a:hover, a:active, a:visited {
  color: #e3e0c8;
}
/*# sourceMappingURL=index.527977de.css.map */
