/* Import color variables */
@use "colors" as *;

/* AntD Switch */
.ant-switch {
    background-color: $color6 !important;
    border-color: $color6 !important;
  
    &-checked {
      background-color: $color2 !important;
      border-color: $color2 !important;
    }
  
    &-disabled {
      background-color: $color4 !important;
      border-color: $color4 !important;
      opacity: 0.5;
    }
  
    &-loading {
      background-color: $color3 !important;
      border-color: $color3 !important;
    }
  
    // .ant-switch-handle {
    //   background-color: $color1 !important;
    // }
  
    // &-checked .ant-switch-handle {
    //   background-color: $color1 !important;
    // }
  
    // &-disabled .ant-switch-handle {
    //   background-color: $color3 !important;
    // }
  }
