/* Import color variables */
@use "colors" as *;

/* AntD Select Dropdown */
.ant-select-dropdown {
  background-color: $color2 !important;
  border-color: $color2 !important;
  color: $color1 !important;

  .ant-select-item {
    background-color: $color2 !important;
    color: $color1 !important;

    &:hover {
      background-color: lighten($color2, 10%) !important;
      color: $color1 !important;
    }

    &.ant-select-item-option-selected {
      background-color: $color4 !important;
      color: $color2 !important;
    }

    &.ant-select-item-option-disabled {
      background-color: $color5 !important;
      color: $color1 !important;
    }
  }

  .ant-select-item-option-content {
    color: $color1 !important;
  }

  .ant-select-item-option-state {
    color: $color5 !important;
  }
}

/* Custom styles for Ant Design select dropdown arrows */
.ant-select-arrow {
  color: $color2 !important;
}

.ant-select-arrow:hover {
  color: lighten($color2, 10%) !important;
}

.ant-select-arrow:focus {
  color: $color2 !important;
}

/* Custom styles for Ant Design select dropdown selector */
.ant-select-selector {
  background-color: $color6 !important;
  border-color: $color6 !important;
  color: $color2 !important;

  &:hover,
  &:focus {
    border-color: $color5 !important;
    box-shadow: 0 0 0 2px rgba($color5, 0.2) !important;
  }
}
