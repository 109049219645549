/* Import color variables */
@use "colors" as *;

/* AntD Card */

.antd-card {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0px;
  color: $color1;
  border: 2px solid $color3;
  background-color: $color2;
  border-radius: 7px;

  .ant-card-head {
    background-color: $color3;
    border-radius: 0px;
    border-color: $color3;
    font-family: "Cormorant Garamond";
    color: $color1;
    font-size: 22px;
  }
}
