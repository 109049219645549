/* Import color variables */
@use "colors" as *;

/* AntD Primary Buttons */
.ant-btn-primary {
  background-color: $color2 !important;
  border-color: $color2 !important;
  color: $color1 !important;

  &:hover,
  &:focus {
    background-color: lighten($color2, 20%) !important;
    border-color: lighten($color2, 20%) !important;
    color: $color1 !important;
  }

  &:active {
    background-color: darken($color2, 10%) !important;
    border-color: darken($color2, 10%) !important;
    color: $color1 !important;
  }

  &:disabled {
    background-color: $color6 !important;
    border-color: $color6 !important;
    color: $color3 !important;
  }
}

/* AntD Default Buttons */
.ant-btn-default {
  background-color: $color1 !important;
  border-color: $color3 !important;
  color: $color3 !important;

  &:hover {
    background-color: $color6 !important;
    border-color: $color5 !important;
    color: $color5 !important;
  }

  &:active {
    background-color: $color6 !important;
    border-color: $color4 !important;
    color: $color4 !important;
  }

  &:disabled {
    background-color: $color6 !important;
    border-color: $color6 !important;
    color: $color3 !important;
  }
}

/* AntD Dashed Buttons */
.ant-btn-dashed {
  background-color: $color1 !important;
  border-style: dashed !important;
  border-color: $color2 !important;
  color: $color2 !important;

  &:hover {
    background-color: $color6 !important;
    border-color: lighten($color2, 10%) !important;
    color: darken($color2, 10%) !important;
  }

  &:active {
    background-color: $color2 !important;
    border-color: $color3 !important;
    color: $color1 !important;
  }

  &:disabled {
    background-color: $color6 !important;
    border-color: $color6 !important;
    color: $color3 !important;
  }
}

/* AntD Danger Buttons */
.ant-btn-danger, .ant-btn-dangerous {
  background-color: $color5 !important;
  border-color: $color5 !important;
  color: $color1 !important;

  &:hover,
  &:focus {
    background-color: lighten($color5, 15%) !important;
    border-color: lighten($color5, 15%) !important;
    color: $color1 !important;
  }

  &:active {
    background-color: darken($color5, 10%) !important;
    border-color: darken($color5, 10%) !important;
    color: $color1 !important;
  }

  &:disabled {
    background-color: $color4 !important;
    border-color: $color4 !important;
    color: $color3 !important;
  }
}