/* Import color variables */
@use "colors" as *;

/* AntD Pagination */
.ant-pagination-item {
  background-color: $color6 !important;
  border-color: $color6 !important;

  a {
    color: $color2 !important;
  }

  &:hover {
    background-color: lighten($color6, 10%) !important;
    border-color: lighten($color6, 10%) !important;

    a {
      color: $color2 !important;
    }
  }
}

.ant-pagination-item-active,
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover, 
.ant-pagination-item-active:active {
  background-color: $color2 !important;
  border-color: $color3 !important;

  a {
    color: $color1 !important;
  }

  &:hover {
    background-color: $color2 !important;
    border-color: $color5 !important;

    a {
      color: $color5 !important;
    }
  }
}

.ant-pagination-item:hover {
  background-color: $color6 !important;
  border-color: $color5 !important;

  a {
    color: $color5 !important;
  }
}

.ant-pagination-item:visited {
  background-color: $color3 !important;
  border-color: $color3 !important;

  a {
    color: $color1 !important;
  }
}

.ant-pagination-item:active {
  background-color: $color5 !important;
  border-color: $color5 !important;

  a {
    color: $color1 !important;
  }
}

/* Custom styles for Ant Design pagination ellipses */
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  .ant-pagination-item-link-icon {
    color: $color4 !important;
  }
}

.ant-pagination-jump-prev:hover,
.ant-pagination-jump-next:hover {
  .ant-pagination-item-link-icon {
    color: lighten($color4, 10%) !important;
  }
}

.ant-pagination-jump-prev:focus,
.ant-pagination-jump-next:focus {
  .ant-pagination-item-link-icon {
    color: $color4 !important;
  }
}
